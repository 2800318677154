import React, { useState } from 'react';
import parseDate from '../utils/parseDate';
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import '../theme/type.scss';
import CustomFooter from '../components/CustomFooter';
import GdprCookies from '../components/GdprCookies';
import HeaderLayout from '../components/layout/HeaderLayout';
import "../theme/layout.scss"

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

import ModalSuscribe from '../components/layout/ModalSuscribre';


const Comentario = ({data}) =>{
    const Comentario = data.comentario.edges;

    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const todosPerPage = 5;
    const totalCards = Comentario;
    const indexOfLastTodo = (currentPage * todosPerPage);
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const cards = totalCards.slice(indexOfFirstTodo,indexOfLastTodo);
    const totalPages = Math.ceil(totalCards.length / todosPerPage);

    //functions modal suscribe
    const [open, setOpen] = useState(false);
    function openSuscribe(){
      setOpen(true);
    }
    function closeSuscribe(){
      setOpen(false);
    }

    return (
      <>
        <HeaderLayout mb={true} suscribe={true} openModal={openSuscribe}/>
        <div className='wrap-content'>
          <div className='header--section'>
            <h2 className='title--section'>Comentario diario histórico</h2>
          </div>
            {cards && cards.map(({ node }) => {
              const { titulo, texto, createdAt, autor} = node
              return (
                <div className="item">
                  <p className="date">{parseDate(createdAt)}</p>
                  <h2 className="title">{titulo}</h2>
                  <p className="resume-content">{texto.raw ? renderRichText(texto):texto}</p>
                  <div className="item--footer">
                    <p className="autor">{autor && autor?.nombre}</p>
                  </div>
                </div>
              )
            })}
            <div className='wrap-pagination'>
            <div className='pagination'>
              <FirstPageIcon fontSize='small' onClick={()=>setCurrentPage(1)}/>
              <NavigateBeforeIcon fontSize='small' onClick={()=>{
                if(currentPage > 1){
                  setCurrentPage(currentPage-1)
                }
                console.log("index of", indexOfLastTodo)
                console.log("index first", indexOfFirstTodo)
              }}/>
              <div className='text'>{currentPage}</div>
              <div className='text de'>de</div>
              <div className='text'>{totalPages}</div>
              <NavigateNextIcon fontSize='small' onClick={()=>{
                if(currentPage < totalPages ){
                  setCurrentPage(currentPage+1);
                }
                console.log("index of", indexOfLastTodo)
                console.log("index first", indexOfFirstTodo)
              }}/>
              <LastPageIcon fontSize='small' onClick={()=>setCurrentPage(totalPages)}/>
            </div>
          </div>
        </div>
        <CustomFooter openModal={openSuscribe} />
        <GdprCookies />
        {
          open && <ModalSuscribe closeModal={closeSuscribe}/>
        }
      </>
    )
}

export default Comentario

export const pageQuery = graphql`
query MyQueryComentarioTodos{
    
    comentario: allContentfulComentarioDiario(
        sort: { fields: createdAt, order: DESC }
      ) {
        edges {
          node {
            titulo
            texto{
                raw
            }
            createdAt
            autor {
                nombre
            }
          }
        }
      }
      

  }
`;
